export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const DIGITAL = '/profileDigital';
export const PLAYER = '/profileAllPlayer';
export const MANAGER = '/profileManager';
export const COACH = '/profileCoach';
export const TRAINER = '/profileTrainer';
export const COMMERCIALTRANSACTIONS = '/commercialTransactions';
